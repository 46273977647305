import React from 'react'

import Editor from "react-umeditor";
class BonusUmeditor extends React.Component {
    constructor() {
        super();
        this.state = {
            content: ""
        }
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.setState({
            content:this.props.detailData.ActivityContent
        })
    }
    componentDidCatch(error, errorInfo) {

        console.log('==============', error, errorInfo);
    }

    onTabsCallback(key) {
        console.log(key);
      }



    handleChange(content) {
        console.log(998823,  content)
        this.setState({
            content: content
        })
        this.props.onEditCallback(content);
    }
    getIcons() {
        var icons = [
            "source | undo redo | bold italic underline strikethrough fontborder emphasis | ",
            "paragraph fontfamily fontsize | superscript subscript | ",
            "forecolor backcolor | removeformat | insertorderedlist insertunorderedlist | selectall | ",
            "cleardoc  | indent outdent | justifyleft justifycenter justifyright | touppercase tolowercase | ",
            "horizontal date time  | image emotion spechars | inserttable"
        ]
        return icons;
    }

    getPlugins() {
        return {
            "image": {
                "uploader": {
                    "type": 'ali',
                    "request": "Data.url",
                    "name": "file",
                    "url": "http://ms-test.sixsix.shop/hapi/upload/uploadImg",
                    // "url": "http://192.168.1.38:8090/upload/uploadImg",
                    filter: function (res) {
                        return res.Data.url;
                    },

                }
            }
        }
    }
    render() {
        var icons = this.getIcons();
        var plugins = this.getPlugins();
        return (
            <Editor
            ref="editor"
           icons={icons}
           value={this.state.content} 
           onChange={this.handleChange.bind(this)}
           plugins={plugins} />
           



        )
    }
}

export default BonusUmeditor