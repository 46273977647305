import React, { Component } from 'react';
import './css/app.css';
import './css/comm.css';
import './css/iconfont/iconfont.css';
import './css/iconfont-new/iconfont.css';
import { Button, Collapse, Dropdown, Icon, Layout, LocaleProvider, Menu, message,ConfigProvider,Tooltip } from 'antd';
import { setDefaultTitle, updateAgreeTotalSize, updateOrder,update } from "./js/reduxs/reducers/iconRedux";
import { connect } from 'react-redux'
import Route from "react-router-dom/es/Route"
import Switch from "react-router-dom/es/Switch"
import withRouter from "react-router-dom/es/withRouter"


import StorageTools from "./js/comm/storageTools";

import { settingAuthority } from "./js/comm/chainAuthoritySetting";
import { $mzlApi, $shopApi,Env, httpPostUrl, notify } from "./js/comm/mzl-core"
import ApiConfig, { FnMenuId, mzlCopyright, mzlPhone, mzlVersion,ModuleId, appHeadImg } from "./js/comm/constans";
import zhCN from 'antd/es/locale/zh_CN'
import { copyProject, getIcon, messageError, messageInfo, log, hideLoadding, showLoadding, showMessageError, authControl,formateObjToParamStr ,sha1Code} from './js/comm/utils';
import { updataCompany } from "./js/reduxs/reducers/shopSettingRedux";

import TrafficLight from "./js/components/light";
import "./css/antd.less";
import api from "./js/comm/api-names";
import APINames from "./js/comm/api-names";
import { requestAgreeSelect, updateOrderSize } from "./js/comm/utilNet";
import IButton from './js/components/IButton';
import { IHelpPop } from "./js/components/iHelpPop";
import IDrawer from "./js/components/iDrawer";
import { IModal } from "./js/components/iModal";

import buyUtil, { BuyUtils } from "./js/buy/buyUtil";
import * as DateHelp from "./js/comm/dateHelp";
import { Auto } from "@comm/iEnum"
import Loadable from "./Loadable";
import { AppRoute, RoutePath } from "./routes/router";
import TipModal from './js/buy/tipModal';
import moment from 'moment';
import 'moment/locale/zh-cn';
import baseService from "@src/base/baseService/baseService";
import { setTimeout } from 'timers';
var service = new baseService();

const { Header,Content, Sider  } = Layout;
const Panel = Collapse.Panel;
const IconFont = getIcon(Icon);
var w = document.documentElement.clientWidth;
var h = document.documentElement.clientHeight * 0.95;
var requestTime = 180000;  //通知栏刷新时间
var agreeTime = 900000;  //预约刷新时间
var versionTime = 86400000;  //版本时间

const { SubMenu } = Menu;
var noticeStart = true;
var timeNotice = null;
var timeAgree = null;
var timeVersion = null;
var mzlVersionInfo = null;
let haveJurisdiction;
let timer;
let chainSetting = [],
    shopSetting = [],
    reportSetting = [],
    num = 0;

let isRequest = true;
let identity={}

let userInfo={}

const reduxStaste = (state)=>{
    return {
        ...state.shopSetting,
        ...state.iconRedux,
    }
}

@withRouter
@connect(
    state => reduxStaste(state),
    { setDefaultTitle, updateOrder, updateAgreeTotalSize,updataCompany,update }
)
class App extends Component {
    state = {
        toUrl: "",
        toRedirect: false,
        contentView: null,



    };

    constructor() {
        super();
        this.state = {
            slidingMenu: null,
            orderSize: 0,
            titleBar: [],
            useBar: [],
            notUsed: [],
            barVisible: false,
            isEditBar: true,
            visible: false,
            noticeData: [],
            noticeCount: 0,
            isShowNoticeRed: false,//通知提示红点
            noticePopData: {},
            noticePopVisible: false,
            timeOver: false,////版本时间到期
            timeOverHint: false,//过期提醒
            VersionInfo: {},//版本信息
            identyVisible: false,
            identyList: [],
            loginData: {},
            agreeTotalSize: {},
            readNotice: [],
            identyListChain: StorageTools.getUserChainIdenflty(),//连锁身份
            visibleChain: false,
            timer: null,
            AppUid: null,
            upVisible: false,
            tipVisible: false,
            authName: '',
            systemHintColse:true,
            selectTitleKey:"", //选择的标题
            selectTitleName:"", //选择的标题名字


        }
    }

    componentWillMount() {
        
        isRequest = true;
        this.requestMzlVersion();
        noticeStart = true;
        this.setState({ identyList: JSON.parse(StorageTools.getUserIdenty()) })
    }

    componentDidMount() {
        num = 0
        window.buyKey = 0;
        updateOrderSize((size) => {
            this.props.updateOrder({ updateOrderSize: size });
        }, this);
        this.props.setDefaultTitle("10000");
        this.getNavigationBar();


        var code = StorageTools.getUserCode();
        if (code != null && code != "") {
            if (timeNotice != null) {
                clearInterval(timeNotice);
            }
            if (timeAgree != null) {
                clearInterval(timeAgree);
            }
            if (timeVersion != null) {
                clearInterval(timeVersion);
            }
            timeNotice = setInterval(this.getNotice(this), requestTime);
            timeAgree = setInterval(this.getAgreeNotice(this), agreeTime);
            timeVersion = setInterval(this.getVersion(this), versionTime);
        }
        var user = StorageTools.getUserInfo();
        if (user) {
            this.setState({
                buyModuleData: {
                    mzlPhone: mzlPhone,
                    mzlCopyright: mzlCopyright,
                    phone: user.Phone,
                    buyId: user.EmployeeId,
                    RealName: (user.RealName),
                    userCode: StorageTools.getUserCode(),
                    mzlVersion: mzlVersion,
                    mchCode: StorageTools.getMchCode(),
                    mchName: (StorageTools.getMchInfo().MchName),
                    mchId: StorageTools.getMchId(),
                    shopName: StorageTools.getUserInfo().StoreName,

                }
            })
        }




    }

    
    /**
     * 初始化导航栏
     */
    initNavigationBar() {
        var bar = [];
        //使用中
        var useBar = [
            { key: FnMenuId.head_home, name: "数据总览", isUsed: true },
            { key: FnMenuId.head_collect, name: "收银开单", isUsed: true },
            { key: FnMenuId.head_order, name: "订单查询", isUsed: true },
            { key: FnMenuId.head_agree, name: "预约到店", isUsed: true }, 
            //更名为 扫码报表，放入报表二级菜单 { key: FnMenuId.head_finance, name: "财务管理", isUsed: false },
            { key: FnMenuId.head_report, name: "店铺报表", isUsed: false ,child:[
                { key: FnMenuId.head_report_yysj2, name: "营业数据", isUsed: false,routePath:"/home/reportContent/businessData2" },
                { key: FnMenuId.head_report_ygyj, name: "员工业绩", isUsed: false ,routePath:"/home/reportContent/employeeperformance"},
                { key: FnMenuId.head_report_yysj, name: "会员报表", isUsed: false ,routePath:"/home/reportContent/vipreport"},
                { key: FnMenuId.head_report_yyfx, name: "营业分析", isUsed: false ,routePath:"/home/reportContent/businessAnalysis"},
                { key: FnMenuId.head_report_kdxf, name: "跨店消费", isUsed: false ,routePath:"/home/reportContent/consume"},
                { key: FnMenuId.head_finance, name: "扫码报表", isUsed: false ,routePath:"home/financeContent"},
            ]},
            { key: FnMenuId.head_vip, name: "会员管理", isUsed: false ,child:[
                { key: FnMenuId.head_vip_hymd, name: "会员名单", isUsed: false,routePath:"/home/vipContent/memberList" },
                { key: FnMenuId.head_vip_kkyx, name: "开卡营销", isUsed: false ,routePath:"/home/vipContent/openCardMarket"},
                { key: FnMenuId.head_vip_czyx, name: "充值营销", isUsed: false ,routePath:"/home/vipContent/rechargeMarketing"},
                { key: FnMenuId.head_vip_hyfx, name: "顾客分析", isUsed: false ,routePath:"/home/vipContent/vipAnalysis"},
            ]},
            { key: FnMenuId.head_marketing, name: "营销活动", isUsed: false ,child:[
                { key: FnMenuId.head_marketing_dxyx, name: "短信营销", isUsed: false,routePath:"/home/marketContent/messageMarket" },
                { key: FnMenuId.head_marketing_ygfh, name: "智能留客", isUsed: false ,routePath:"/home/marketContent/employeeBonus"},
                { key: FnMenuId.head_marketing_gdfh, name: "股东分红", isUsed: false ,routePath:"/home/marketContent/shareBonus"},
                { key: FnMenuId.head_marketing_sgyx, name: "散客营销", isUsed: false ,routePath:"/home/marketContent/retailMarketing"},
                { key: FnMenuId.head_marketing_xjq, name: "现金券", isUsed: false ,routePath:"/home/marketContent/cashCoupon"},
                { key: FnMenuId.head_marketing_cj, name: "抽奖", isUsed: false ,routePath:"/home/marketContent/luckDraw"},
            ]},
            { key: FnMenuId.head_stock, name: "库存管理", isUsed: false,child:[
                { key: FnMenuId.head_stock_new_spgl, name: "商品管理", isUsed: false ,routePath:"/home/goodsContent/goodsManage" },
                { key: FnMenuId.head_stock_new_rugl, name: "入库管理", isUsed: false ,routePath:"/home/goodsContent/goodsInto" },
                { key: FnMenuId.head_stock_new_ckgl, name: "出库管理", isUsed: false ,routePath:"/home/goodsContent/goodsOut" },
                { key: FnMenuId.head_stock_new_sqdb, name: "申请调拨", isUsed: false,routePath:"/home/goodsContent/goodsApply"  },
                { key: FnMenuId.head_stock_new_kctj, name: "库存统计", isUsed: false,routePath:"/home/goodsContent/goodsCount"  },
                { key: FnMenuId.head_stock_new_rutj, name: "入库统计", isUsed: false,routePath:"/home/goodsContent/goodsIntoCount"  },
                { key: FnMenuId.head_stock_new_cktj, name: "出库统计", isUsed: false ,routePath:"/home/goodsContent/goodsOutCount" },
                { key: FnMenuId.head_stock_new_kcyj, name: "库存预警", isUsed: false,routePath:"/home/goodsContent/goodsWarn"  },
            ] },
            // { key: FnMenuId.head_store, name: "在线商城", isUsed: false },
            { key: FnMenuId.head_setting, name: "店铺设置", isUsed: false ,child:[
                { key: FnMenuId.head_setting, name: "店铺设置", isUsed: false },
                { key: FnMenuId.head_setting_smf, name: "扫码付", isUsed: false },
                { key: FnMenuId.head_setting_kqsz, name: "考勤设置", isUsed: false },
                { key: FnMenuId.head_setting_jfsz, name: "积分设置", isUsed: false },
                { key: FnMenuId.head_setting_lbsz, name: "轮牌手牌", isUsed: false },
                { key: FnMenuId.head_setting_yysz, name: "预约设置", isUsed: false },
                { key: FnMenuId.head_setting_tysz, name: "通用设置", isUsed: false },
                { key: FnMenuId.head_setting_kdxf, name: "跨店消费", isUsed: false },
            ]},
            { key: FnMenuId.user_settting, name: "账号管理", isUsed: false ,child:[
                { key: FnMenuId.user_settting_rjjh, name: "软件激活", isUsed: false,routePath:"/home/userCenterContent/softRenewal" },
                { key: FnMenuId.user_settting_yhzx, name: "用户中心", isUsed: false ,routePath:"/home/userCenterContent/userAccoutSetting"},
            ]},
            
            
        ];
        bar.push(...useBar);
        StorageTools.saveNavigationBar(bar);
        return bar;
    }

    //轮询通知 3分钟一次
    getNotice(that) {
        
        this.requestQueryNoticeRecords();
    }

    //轮询版本控制 1天1次
    getVersion(that) {
        service.getUserProd(that)
    }

    //轮询预约 10分钟一次
    getAgreeNotice(that) {
        this.requestSoSelect();
    }




    /**
     * 查询已预约的数据
     */
    requestSoSelect() {
        requestAgreeSelect(this,(size) => {
            this.props.updateAgreeTotalSize({ agreeTotalSize: size });
        });
    }

    /**
     * 查店铺信息
     */
    requestConfirmLogin() {
        var map = new Map();
        map.set('shopCode', this.state.loginData.ShopCode);
        map.set('userCode', StorageTools.getStoreCode());
        map.set('empId', this.state.loginData.AppUid);
        $mzlApi.post(APINames.API_confirmLogin, map, (res) => {
            // console.log('店铺信息', res)
            this.setState({ identyVisible: false })
            StorageTools.saveUserInfo(res)
            StorageTools.saveUserCode(res.StoreCode)
            StorageTools.saveStoreName(res.StoreName)
            window.StoreName = res.StoreName;
            if (res.StoreName == '') {
                this.props.history.push('/home/settingContent/shopSetting');
                messageInfo('请完善店铺信息')
            } else {
                this.props.history.push('/home')
            }
        }, this.httpFail.bind(this), this);
    }


    

    //版本检测
    requestMzlVersion() {
        var map = new FormData();
        // map.append("product", "mzl-windows");
        map.append("product", "mzl-windows");
        map.append("version", mzlVersion);
        map.append("channel", "stable");
        $mzlApi.post(APINames.API_checkPatch, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    //查询已读通知
    requestQueryNoticeRecords() {
        let code = StorageTools.getUserCode();
        if (code == null || code == "") { return }
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        $mzlApi.post(APINames.queryNoticeRecords, map, (res) => {
            // this.getNotice();
            if (res != null) {
                this.requestQueryNotice(res);
            }
        }, this.httpFail.bind(this), this);

    }


    //通知推送
    requestQueryNotice(readNotice) {
        var map = new FormData();
        map.append("url", APINames.API_queryNotices);
        map.append("params",JSON.stringify({
            channel:"2"
        }))
        $mzlApi.post(APINames.API_getProdApi, map,(res)=>{
            let noticeData = JSON.parse(res.Data.data)

            if (noticeData != null && noticeData.length > 0) {
                this.state.noticeCount = noticeData.length;
                noticeData.forEach(item => {
                    item.open = readNotice.some(s=>{
                        return item.id == s.NoticeId
                    })?1:0;
                    this.state.noticeCount= this.state.noticeCount - item.open;
                });
                let notice= noticeData[0];
                if(notice.bullet_screen === 1 && notice.open === 0){
                    this.requestGetNotice(notice, true);
                    this.setState({
                        noticeData,
                        isShowNoticeRed: true
                    })
                }else{
                    this.setState({
                        noticeData
                    })
                }
            }
        }, this.httpFail.bind(this), this);
    }




    //保存已读通知
    requestSaveNoticeRecords(record) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('record', record);
        $mzlApi.post(APINames.saveNoticeRecord, map, (res) => {

        }, this.httpFail.bind(this), this);

    }

    requestGetNotice(item, pop) {
        var map = new FormData();
        map.append("params",JSON.stringify({
            noticeId:item.id+""
        }))
        map.append("url", APINames.API_noticeId);
        $mzlApi.post(APINames.API_getProdApi, map, (res) => {
            res.data = JSON.parse(res.Data.data);
            if (res != null && res.data != null) {
                item.content = res.data.content;
                if (pop) {
                    StorageTools.saveNoticeTime(item.createTime);
                    this.setState({
                        noticePopVisible: true,
                        noticePopData: item
                    })
                }
                this.setState(this)
            }

        }, this.httpFail.bind(this),this);
    }



    //升级
    requestUpgrade(mchcode) {
        isRequest = false
        showLoadding()
        // setTimeout(hide, 2500);
        var map = new FormData();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('mchCode', mchcode);
        $mzlApi.post(APINames.API_upgradeMerchant, map, (res, tag) => {
            message.success('升级连锁商户成功,请稍后~');
            if (this.state.timer != null) {
                clearInterval(this.state.timer)
            }
            this.state.timer = setInterval(() => {
                if (num > 30) {
                    hideLoadding()
                    isRequest = true
                    clearInterval(this.state.timer)
                    messageError('身份请求失败,请重试')
                    num = 0
                }
                num++;
                this.requestQueryUserIdentityChain()
            }, 2000);

        }, this.httpFail.bind(this), this);
    }

     //商城
     getShopUrlEntrance() {
        let userProd =StorageTools.getUserInfo();
        console.log(8123,userProd)
        showLoadding()
        var map = new FormData();
        map.set('shopCode', StorageTools.getUserCode());
        map.set('entranceType', 1); //1商城后台，2个人用户
        $shopApi.post(APINames.API_getShopUrlEntrance, map, (res, tag) => {
            hideLoadding();
           if(res.Data){
                let data = JSON.parse(res.Data.data) 
                let time = new Date().getTime();
                let obj = {
                id:data.EntranceId,
                identify:sha1Code(data.EntranceIdentify+time),
                stamp:time,
                valid:2,
            }
            let dd = formateObjToParamStr(obj);
            // window.location.href = data.EntranceBackurl+"?"+dd;
            if (!notify.appSend("open-url", {channel: "mzl", data:{url:data.EntranceBackurl,params:obj}})) {
                window.open(data.EntranceBackurl+"?"+dd)
            }
            // window.open(data.EntranceBackurl+"?"+dd)
           }else{
               this.getShopInfo();
           }

        }, this.httpFail.bind(this), this);
    }

    getShopInfo(){
        showLoadding()
        let userProd =StorageTools.getUserProd();
        let shopCode =StorageTools.getUserCode();
        let serial = userProd.serial;
        let modules = userProd.modules;
        let version = "";
        if(modules && modules !== ''){
            modules.forEach(e=>{
                if(e.module_id === ModuleId.store1){
                    version = "V1";
                }else if(e.module_id === ModuleId.store2){
                    version = "V2";
                }else if(e.module_id === ModuleId.store3){
                    version = "V3";
                }
            })
        }
       service.getShopInfo(this,shopCode,serial.expires_time+"",version,(res)=>{
            hideLoadding();
            this.saveShopUrlEntrance(res);
       })
    }
        
    
      //保存商城用户
      saveShopUrlEntrance(data) {
        showLoadding()
        let entrance = SShopUrlEntrance();
        entrance.ShopCode = StorageTools.getUserCode();
        entrance.EntranceId = data.id;
        entrance.EntranceType = 1;
        entrance.EntranceIdentify = data.identify;
        entrance.EntranceBackurl = data.backurl;
        entrance.EntranceFronturl = data.fronturl;
        var map = new FormData();
        map.set('shopCode', StorageTools.getUserCode());
        map.set('entrance', JSON.stringify(entrance)); //1商城后台，2个人用户
        $shopApi.post(APINames.API_saveShopUrlEntrance, map, (res, tag) => {
            hideLoadding();
            if(res){
                let time = new Date().getTime();
                 let obj = {
                    id:data.id,
                    identify:sha1Code(data.identify+time),
                    stamp:time,
                    valid:2,
                }
                let dd = formateObjToParamStr(obj);
                if (!notify.appSend("open-url", {channel: "mzl", data:{url:data.backurl,params:obj}})) {
                    window.open(data.backurl+"?"+dd)
                }
                // window.open(data.backurl+"?"+dd)
                // window.open(data.backurl+"?"+dd)
                // window.location.href = data.backurl+"?"+dd;
                // console.log(123123,"http:\/\/mzl.mlmesm.cn\/web\/index.php?"+dd);
            }
           



        }, this.httpFail.bind(this), this);
    }




    /**
     * 查询用户身份是否有连锁店铺的权限
     */
    async requestQueryUserIdentityChain() {
        var map = new Map();
        map.set('userCode', JSON.parse(StorageTools.getStoreCode()));
        map.set('appFlag', 128);
        map.set('type', 2)
        $mzlApi.post(APINames.API_queryUserIdentity, map, (res) => {

            // MchType为1有权限 为0没有
            if (res.length) {
                isRequest = true
                hideLoadding()
                clearInterval(this.state.timer)
                res.forEach(item => {
                    item._borderColor = '#D8D8D8';
                    item._color = '#333';
                    if (item.IdentityFlag == 1) {
                        item._IdentityFlag = '店长'
                    } else if (item.IdentityFlag == 4) {
                        item._IdentityFlag = '收银员'
                    } else if (item.IdentityFlag == 64) {
                        item._IdentityFlag = '超级管理员'
                    } else if (item.IdentityFlag == 128) {
                        item._IdentityFlag = '店铺管理员工'
                    }

                    item.ShopName = item.MchName


                });
                if (res.length == 1) {
                    chainSetting = []
                    reportSetting = []
                    let arrChainObj = {}
                    // console.log('升级之后1中身份')
                    StorageTools.saveMchInfo(res[0]);
                    StorageTools.saveMchCode(res[0].mchCode)
                    const ss = res[0]
                    if (ss.IdentityFlag && ss.Extra) {

                        arrChainObj = settingAuthority(ss.IdentityFlag, ss.Extra, true)
                        chainSetting = arrChainObj.chainSetting
                        reportSetting = arrChainObj.reportSetting

                    }
                    if (chainSetting.length) {
                        // console.log(1111, '有连锁权限');
                        var config = StorageTools.getConfig();
                        config.uploadState = 1;
                        StorageTools.saveConfig(config);
                        this.props.history.push({ pathname: RoutePath.chain_settingContent_setting_chainasetting, state: 1 });
                        // this.props.history.push('/chain/settingContent/setting/chainasetting/1')
                    } else {
                        if (reportSetting.length) {
                            // console.log(1111, '有报表权限')
                            this.props.history.push("/chain/chainReport/overVier");
                        } else {
                            messageInfo('您没有连锁权限!')
                        }
                    }

                } else if (res.length >= 2) {
                    // console.log('升级之后有两种身份')
                    if (!this.state.AppUid) {
                        this.setState({ identyListChain: res, visibleChain: true })
                        return;
                    }
                    res.map((item, index) => {
                        if (item.AppUid == this.state.AppUid) {
                            chainSetting = []
                            reportSetting = []
                            let arrChainObj = {};
                            StorageTools.saveMchInfo(item);
                            StorageTools.saveMchCode(item.mchCode)
                            const ss = item

                            if (ss.IdentityFlag && ss.Extra) {

                                arrChainObj = settingAuthority(ss.IdentityFlag, ss.Extra, true)
                                chainSetting = arrChainObj.chainSetting
                                reportSetting = arrChainObj.reportSetting

                            }
                            if (chainSetting.length) {
                                // console.log(1111, '有连锁权限')
                                this.props.history.push({ pathname: RoutePath.chain_settingContent_setting_chainasetting, state: 1 });
                                // this.props.history.push('/chain/settingContent/setting/chainasetting/1')
                            } else {
                                if (reportSetting.length) {
                                    // console.log(1111, '有报表权限')
                                    this.props.history.push("/chain/chainReport/overVier");
                                } else {
                                    messageInfo('您没有连锁权限!')
                                }
                            }
                        }
                    })

                }



                // this.setState({ mchInfoArr: res })
            }
        }, this.httpFail.bind(this), this);
    }



    /**
     * 查询用户身份是否有连锁店铺的权限
     */
    async requestQueryUserChain() {
        var map = new Map();
        map.set('userCode', StorageTools.getUserCode());
        map.set('appFlag', 128);
        map.set('type', 2)
        $mzlApi.post(APINames.API_queryUserIdentity, map, async (res) => {
            // MchType为1有权限 为0没有
            if (res.length) {
                res.forEach(item => {
                    item._borderColor = '#D8D8D8';
                    item._color = '#333';
                    if (item.IdentityFlag == 1) {
                        item._IdentityFlag = '店长'
                    } else if (item.IdentityFlag == 4) {
                        item._IdentityFlag = '收银员'
                    } else if (item.IdentityFlag == 64) {
                        item._IdentityFlag = '超级管理员'
                    } else if (item.IdentityFlag == 128) {
                        item._IdentityFlag = '店铺管理员工'
                    }
                    
                    item.ShopName = item.MchName
                    

                });
               await  this.setState({ mchInfoArr: res })
            }
            // StorageTools.saveMchInfo(res[0])
            // if(res.length && res[0].Extra.MchType == 1){
            //     StorageTools.saveMchCode(res[0].mchCode)
            // }

        }, res=>{

        }, this);
    }



    httpSuccess(res, tag) {
        if (tag == APINames.API_getProdApi) {
            res.data = JSON.parse(res.Data.data) 
            // debugger
            if (res != null && res.data != null && res.data.length > 0) {
                this.state.noticeCount = res.data.length;
                res.data.forEach(item => {
                    // this.state.noticeCount++;
                    item.open = 0;
                    for (let j in this.state.readNotice) {
                        let i = this.state.readNotice[j];
                        if (item.id == i.NoticeId) {
                            // console.log(1111,i.NoticeId);
                            item.open = 1;
                            return;
                        }
                    }
                });

                var openSize = 0;
                res.data.forEach(item => {
                    if (item.open == 1) {
                        openSize++;
                    }
                })
                this.state.noticeCount = this.state.noticeCount - openSize;
                var noticeTime = StorageTools.getNoticeTime();
                var item = res.data[0];
                if (noticeTime == null || (item.createTime != noticeTime && item.state == 1)) {
                    if (mzlVersionInfo != null && mzlVersionInfo.Latest) {
                        if (item.bulletScreen == 1) {
                            this.requestGetNotice(item, true);
                        }
                    }
                    this.setState({
                        noticeData: res.data,
                        isShowNoticeRed: true
                    })
                } else {
                    this.setState({
                        noticeData: res.data
                    })
                }
            }

        } else if (tag == APINames.API_checkPatch) { //版本信息
            if (res != null) {
                if (!res.Latest) {
                    this.setState({ noticePopVisible: false })
                }
                mzlVersionInfo = res;
            }
        } else if (tag == APINames.API_getMchMerchant) {
            hideLoadding()
            if (res && res.Data && res.Data.mchMerchant) {
                const shopInfomchMerchant = JSON.parse(res.Data.mchMerchant)
                //保存企业名字
                this.props.updataCompany({
                    companyName: shopInfomchMerchant.CompanyName
                });
            }

        }








    }

    httpFail(res, tag) {
        showMessageError(res)
        hideLoadding()
        switch (tag) {
            case APINames.API_upgradeMerchant:
                if (this.state.timer != null) {
                    clearInterval(this.state.timer)
                }
                showLoadding()
                this.state.timer = setInterval(() => {
                    if (num > 30) {
                        hideLoadding()
                        isRequest = true
                        clearInterval(this.state.timer)
                        messageError('身份请求失败,请重试')
                        num = 0

                    }
                    num++;
                    this.requestQueryUserIdentityChain()
                }, 2000);
                break;
            case APINames.API_queryUserIdentity:
                clearInterval(this.state.timer);
                isRequest = true
                break;
        }
    }



    setNavigationData(bool, data) {
        if (data.key == FnMenuId.head_home) {
            messageError("首页不支持编辑");
            return;
        }
        if (data.key == FnMenuId.head_collect) {
            messageError("收银不支持编辑");
            return;
        }
        if (data.key == FnMenuId.head_order) {
            messageError("订单不支持编辑");
            return;
        }
      
        var temp = [];
        if (bool) {
            this.state.useBar.forEach((item => {
                if (item.key != data.key) {
                    temp.push(item);
                } else {
                    item.isUsed = false;
                    this.state.notUsed.push(item);
                }
            }));

            this.setState({
                useBar: temp,
                notUsed: this.state.notUsed
            });
        } else {
            this.state.notUsed.forEach((item => {
                if (item.key != data.key) {
                    temp.push(item);
                } else {
                    item.isUsed = true;
                    this.state.useBar.push(item);
                }
            }));

            this.setState({
                useBar: this.state.useBar,
                notUsed: temp
            });
        }
        // console.log("1111",this.state.useBar,this.state.notUsed,temp)
    }



    onClickTitle(res) {
        console.log('标题', res);
        //dropdown 避免多次调用

        // console.log('全局变量', window.StoreName, StorageTools.getStoreName())
        if (res.key == FnMenuId.head_reset) { //切换账号
            notify.appSend("user-login-event", { event: "logout" });
            StorageTools.saveUserInfo({});
            buyUtil.setBuyModule({});
            // buyUtil.setTimeOutVersion({});
            StorageTools.saveUserCode("");

            StorageTools.saveConfig({});
            StorageTools.saveMchCode("");
            StorageTools.saveMchInfo({});
            StorageTools.saveUserChainIdenflty([]);
            StorageTools.saveUserIdenty([]);
            this.props.history.push("/login");
            StorageTools.saveConfig({});
            return;
        }

        if (!StorageTools.getStoreName()) {
            messageInfo('请完善店铺信息')
        } else {
            if(res.key != FnMenuId.head_icon){
                this.state.selectTitleKey = res.key;
            }
            switch (res.key) {
                case FnMenuId.head_home://首页
                this.state.selectTitleName = "首页";
                    this.setState({
                        slidingMenu: null
                    });
                    this.props.history.push(RoutePath.home);
                    break;
                case FnMenuId.head_collect://收银
                this.state.selectTitleName = "收银";
                    this.setState({
                        slidingMenu: null
                    })
                    if (authControl(ModuleId.basics)) {
                        this.props.history.push(RoutePath.home_silverContent)
                    } else {
                        // messageError('您没有收银权限，请先去购买')
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({authName: '收银' })
                    }

                    break;
                case FnMenuId.head_finance://财务
                this.state.selectTitleName = "财务";
                    this.setState({
                        slidingMenu: this.sldingMenu(FnMenuId.head_finance, "财务")
                    })
                    if (authControl(ModuleId.basics)) {
                        this.props.history.push("/home/financeContent");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({  authName: '财务' })
                    }
                    
                   
                    break;
                case FnMenuId.head_order://订单
                this.state.selectTitleName = "订单";
                    this.setState({
                        slidingMenu: null
                    })
                    if (authControl(ModuleId.basics)) {
                        this.props.history.push("/home/orderContent/orderList");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({ tipVisible: true, authName: '订单' })
                    }
                 
                    break;
                case FnMenuId.head_agree://预约
                this.state.selectTitleName = "预约";
                    this.setState({
                        slidingMenu: null
                    })
                    if (authControl(ModuleId.appointment)) {
                        this.requestSoSelect();
                        this.props.history.push("/home/agree");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({ tipVisible: true, authName: '预约' })
                    }

                    break;
                case FnMenuId.head_vip://会员
                this.state.selectTitleName = "会员";
                    
                    if (authControl(ModuleId.basics)) {
                        this.props.history.push("/home/vipContent/memberList");
                    } else {
                        this.props.history.push("/home/vipContent");
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({ tipVisible: true, authName: '会员' })
                    }
                    break;
                case FnMenuId.head_marketing://营销
                this.state.selectTitleName = "营销";
                    
                    if (authControl(ModuleId.basics)) {
                        this.props.history.push("/home/marketContent/messageMarket");
                     } else {
                        this.props.history.push("/home/marketContent");
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({ tipVisible: true, authName: '短信营销' })
                    }
                    break;
                case FnMenuId.head_stock://库存
                this.state.selectTitleName = "库存";
                    
                    if (authControl(ModuleId.stock)) {
                        this.props.history.push("/home/goodsContent/goodsManage");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({ tipVisible: true, authName: '库存' })
                    }
                    break;
                    case FnMenuId.head_store://商城
                    this.state.selectTitleName = "商城";
                    
                    if (authControl(ModuleId.store1)||authControl(ModuleId.store2)|| authControl(ModuleId.store3)) {
                        this.getShopUrlEntrance();

                        // this.props.history.push("/home/stockContent/purchaseManage");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({ tipVisible: true, authName: '商城' })
                    }
                    break;
                    
                case FnMenuId.head_setting://店铺设置
                this.state.selectTitleName = "店铺设置";
                    
                    this.props.history.push("/home/settingContent/shopSetting");
                    // if (authControl(ModuleId.basics)) {
                    //     this.props.history.push("/home/settingContent/shopSetting");
                    // } else {
                    //     // this.props.history.push("/home/settingContent");
                    //     this.props.update({
                    //         tipModalVisible:true
                    //     })
                    //     this.setState({ tipVisible: true, authName: '店铺设置' })
                    // }
                    
                    break;
                case FnMenuId.head_userCenter://用户中心
                this.state.selectTitleName = "用户中心";
                    
                    this.props.history.push("/home/userCenterContent/userAccoutSetting");
                    break
                case FnMenuId.head_report://报表
                this.state.selectTitleName = "报表";
                    
                    if (authControl(ModuleId.basics)) {
                        this.props.history.push("/home/reportContent/businessData2");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({ tipVisible: true, authName: '报表' })
                    }
                    break;

                case FnMenuId.head_rjsq: //软件激活
                this.state.selectTitleName = "软件激活";
                    this.props.update({
                        activationVisible:true
                    });
                    this.props.history.push({pathname:"/home/userCenterContent/softRenewal",query: { activation : true }});
                    break
                case FnMenuId.head_qhsf: //切换身份
                    
                    this.state.selectTitleKey = "";

                    var map = new Map();
                    map.set('userCode', JSON.parse(StorageTools.getStoreCode()));
                    map.set('appFlag', 16);
                    map.set('type', 1)
                    $mzlApi.post(APINames.API_queryUserIdentity, map, (res) => {
                        var arr = []
                        res.forEach(item => {
                            if (item.IdentityFlag == 1) {
                                item._IdentityFlag = '店长'
                            } else if (item.IdentityFlag == 4) {
                                item._IdentityFlag = '收银员'
                            }
                            if (item.IdentityFlag == 1 || item.IdentityFlag == 4) {
                                arr.push(item)
                            }
                        })
                        this.setState({ identyVisible: true, identyList: arr })
                        StorageTools.saveUserIdenty(arr)

                    }, this.httpFail.bind(this), this);
                    break

                case FnMenuId.head_setting: //店铺设置
                    this.props.history.push("/home/settingContent/shopSetting");
                    break

                case FnMenuId.head_setting_smf: //扫码付
                    const userInfo = StorageTools.getUserInfo();
                    if (userInfo.WorkId == "default") {
                        this.props.history.push("/home/settingContent/paySetting")
                    } else {
                        showMessageError("您没有开通扫码付权限，请通知管理员进行开通！");
                    }
                    break
                case FnMenuId.head_setting_kqsz: //考勤设置
                    if (authControl(ModuleId.checkWork)) {
                        this.props.history.push(RoutePath.checkWork);
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({  authName: '考勤设置' })
                    }
                    break
                case FnMenuId.head_setting_jfsz: //积分设置
                    if (authControl(ModuleId.integral)) {
                        this.props.history.push("/home/settingContent/integralSetting");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({  authName: '积分设置' })
                    }
                    break
                case FnMenuId.head_setting_lbsz: //轮班设置
                    if (authControl(ModuleId.sdi)) {
                        this.props.history.push("/home/settingContent/roulette");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({  authName: '轮牌手牌' })
                    }
                    break
                case FnMenuId.head_setting_yysz: //预约设置
                    if (authControl(ModuleId.appointment)) {
                        this.props.history.push("/home/settingContent/reserverSetting");
                    } else {
                        this.props.update({
                            tipModalVisible:true
                        })
                        this.setState({  authName: '预约设置' })
                    }
                    break
                case FnMenuId.head_setting_tysz: //通用设置
                    this.props.history.push("/home/settingContent/backups");
                    break
                case FnMenuId.head_setting_kdxf: //跨店消费
                    this.props.history.push(RoutePath.consumeSetting);
                    break
                default:
                    console.log(11111111111,res)
                    this.state.titleBar.forEach(element => {
                        if(element.child){
                            element.child.forEach(item =>{
                                if (item.routePath && item.key == res.key){
                                    this.props.history.push(item.routePath);
                                }
                            })
                        }
                    });
                    break;
            }
            if (res.key != FnMenuId.head_icon) {
                this.setState({
                    barVisible: false
                })
            }

        }


    }

    /**
     *导航条点击事件
     * @param tag
     */
    onClickDropdown(tag) {
        // console.log(tag)
        switch (tag) {
            case "hide":
                this.setState({
                    barVisible: false
                });
                break;
            case "dropdown":
                this.setState({
                    barVisible: !this.state.barVisible
                });
                break;
           
            default:
                break;
        }
    }


    onItemClick(res) {
        if (!this.state.isEditBar) {
            this.setNavigationData(res.isUsed, res);
            // this.state.useBar
        } else {
            this.onClickTitle(res);
        }
    }
    shopInfo() {
        this.props.history.push('/home/settingContent/shopInfo');
    }



    onClick(e) {
        // console.log()
        e.preventDefault();
        this.props.history.push("/login")
    };

    /**
      * 获取企业信息
      */
    requestGetStore() {
        showLoadding()
        var map = new Map();
        map.set("mchCode", StorageTools.getMchCode());
        $mzlApi.post(
            APINames.API_getMchMerchant,
            map,
            this.httpSuccess.bind(this),
            this.httpFail.bind(this),
            this
        );
    }
    onClickListener(tag, e) {
        // console.log(tag, e)
        log(tag, e);
        switch (tag) {
            case 'tip-Cancel':
                this.props.update({
                    tipModalVisible:false
                })
                // this.setState({ tipVisible: false })
                break
            case "massage":
                if (this.state.noticeData != null && this.state.noticeData.length > 0) {
                    if (this.state.isShowNoticeRed) {
                        this.state.isShowNoticeRed = false;
                        // StorageTools.saveNoticeTime(this.state.noticeData[0].createTime);
                    }
                }
                this.setState({
                    visible: true,
                });
                break;
            case "help":
                break;
            case "drawer-cancel"://对话框取消
                this.setState({ visible: false, saveData: {} })
                break;
            case '关闭升级':
                isRequest = true
                this.setState({ upVisible: false })

                break
            case '升级成功':
                this.requestUpgrade(StorageTools.getMchCode());
                break
            case "CollapseItem"://对话框取消
                // if (e.length > 0) {
                if (e.open == 0) {
                    // this.requestGetNotice(e);
                    e.open = 1;
                    var record = {};
                    record.Id = 0;
                    record.NoticeId = e.id;
                    record.NoticeType = 1;
                    this.requestSaveNoticeRecords(JSON.stringify(record));
                    noticeStart = true;
                    this.setState({
                        noticeCount:this.state.noticeCount-1
                    })
                }

                this.state.noticeData.forEach((item) => {
                    if (item.id == e.id) {
                        this.requestGetNotice(item);
                    }
                });
                // }
                break;
            case "setting-app"://对话框取消
                this.setState({
                    DropdownVisible: true
                });
                break;
            case "settingPop"://对话框取消
                this.setState({
                    DropdownVisible: e
                });
                break;
            case "noticePopVisible"://对话框取消
                let notice = this.state.noticeData[0];
                notice.open = 1;
                var record = {};
                record.Id = 0;
                record.NoticeId = notice.id;
                record.NoticeType = 1;
                this.setState({
                    noticeCount:this.state.noticeCount-1
                })
                this.requestSaveNoticeRecords(JSON.stringify(record));

                // if (this.state.noticeData != null && this.state.noticeData.length > 0) {
                //     if (this.state.isShowNoticeRed) {
                //         this.state.isShowNoticeRed = false;
                //         StorageTools.saveNoticeTime(this.state.noticeData[0].createTime);
                //     }
                // }
                this.setState({
                    noticePopVisible: false
                });
                break;
            case "timeOver"://关闭过期提醒
                // BuyUtils.setCloseTime(DateHelp.getCurrentTime());
                // var list = BuyUtils.getTimeOutVersionList();
                // list.forEach(item => {
                //     if (item.id == e.id) {
                //         item.isClose = false
                //         item.setTime = DateHelp.getCurrentTime();
                //     }
                // })
                // BuyUtils.setTimeOutVersionList(list);
                // var vData = buyUtil.getTimeOutVersion();
                // vData.timeOver = false;
                // vData.timeOverHint = false;
                // vData.setTime = DateHelp.getCurrentTime();
                // vData.isClose = false;

                // StorageTools.saveTimeOutClose();
                // buyUtil.setTimeOutVersion(vData)
                const _this = this;
                this.setState({
                    systemHintColse:false
                },function(){
                    setTimeout(function(){
                        _this.setState({
                            systemHintColse:true
                        })
                    },24*3600*1000)
                });
                break;
            case '进入':
                // console.log(e)
                this.state.identyList.forEach(item => {
                    if (item.AppUid == e.AppUid) {
                        item._borderColor = '#A451F6'
                        item._color = '#fc1a6e'
                    } else {
                        item._borderColor = '#D8D8D8'
                        item._color = '#333'
                    }
                })
                this.setState({ loginData: e, identyList: this.state.identyList }, () => {
                    this.requestConfirmLogin()
                })
                break;
            case 'chain':
                this.openChianPage()
                break;
        }

    }



    async openChianPage(){
        if (!StorageTools.getStoreName()) {
            messageInfo('请完善店铺信息')
            return;
        }
        var config = StorageTools.getConfig();
        if (config.operationId) {
            this.onClickTitle({ key: FnMenuId.head_reset });
            return;
        }
        if (isRequest) {
            await this.requestQueryUserChain()
            if (this.state.identyListChain.length == 0) {
                // console.log('没有身份')
                isRequest = false
                /**
                 *查连锁版本
                 */
                this.requestUpgrade(StorageTools.getMchCode());
                // var map = new Map();
                // map.set('mchCode', StorageTools.getMchCode());
                // $agentApi.post(
                //     APINames.API_queryProdMchAuth,
                //     map,
                //     (res) => {
                //         if (JSON.parse(res.Data.prodMchAuths).length) {
                //             this.requestUpgrade(StorageTools.getMchCode());
                //         } else {
                //             this.setState({ upVisible: true })
                //         }
                //     },
                //     this.httpFail.bind(this),
                //     this
                // );

            } else if (this.state.identyListChain.length == 1) {
                StorageTools.saveMchInfo(this.state.identyListChain[0]);
                StorageTools.saveMchCode(this.state.identyListChain[0].mchCode)
                if (this.state.identyListChain[0].Extra.MchType == 0) {
                    // console.log('1中身份没有升级')
                    isRequest = false
                    /**
                 *查连锁版本
                 */
                this.requestUpgrade(this.state.identyListChain[0].mchCode);
                    // var map = new Map();
                    // map.set('mchCode', StorageTools.getMchCode());
                    // $agentApi.post(
                    //     APINames.API_queryProdMchAuth,
                    //     map,
                    //     (res) => {
                    //         if (JSON.parse(res.Data.prodMchAuths).length) {
                    //             this.requestUpgrade(this.state.identyListChain[0].mchCode);
                    //         } else {
                    //             this.setState({ upVisible: true })
                    //         }
                    //     },
                    //     this.httpFail.bind(this),
                    //     this
                    // );
                } else if (this.state.identyListChain[0].Extra.MchType == 1) {
                    // console.log('1中身份已经升级')
                    isRequest = true
                    const ss = this.state.identyListChain[0]
                    let arrChainObj = {};
                    if (ss.IdentityFlag && ss.Extra) {

                        arrChainObj = settingAuthority(ss.IdentityFlag, ss.Extra, true)
                        chainSetting = arrChainObj.chainSetting
                        reportSetting = arrChainObj.reportSetting

                    }
                    if (chainSetting.length) {
                        this.props.history.push({ pathname: RoutePath.chain_settingContent_setting_chainasetting });
                    } else {
                        if (reportSetting.length) {
                            this.props.history.push("/chain/chainReport/overVier");
                        } else {
                            messageInfo('您没有权限！')
                        }
                    }
                }
            } else if (this.state.identyListChain.length >= 2) {
                // console.log('大于两种身份')
                isRequest = false
                this.setState({ visibleChain: true })
            }

        }
    }




    sldingMenu(key, text) {
        if (key == null) {
            return null;
        }
        return <Menu.Item key={key}>{text}</Menu.Item>
    }


    getNavigationBar() {

        let  bar = this.initNavigationBar();
        this.setState({
            titleBar: copyProject(bar),
            useBar: bar,
        })
    }
    isEdit(key) {
        if (key === FnMenuId.head_home || key === FnMenuId.head_collect || key === FnMenuId.head_order ) {
            return true
        } else {
            return false
        }
    }

    getItembarView(used) {
        var itemBar;
        if (used) {
            itemBar = this.state.useBar.length
                ? this.state.useBar.map((item, index) => (
                    this.isEdit(item.key) ?
                        <div key={index} className="bar-item-text" onClick={this.onItemClick.bind(this, item)}>{item.name} </div>
                        : <div key={index} className="bar-item-text" onClick={this.onItemClick.bind(this, item)}>{item.name} {this.state.isEditBar ? null : <div className="bar-item-icon comm-flex-col-center"><Icon type={used ? "minus" : "plus"} /></div>} </div>
                )) : null;
        } else {
            itemBar = this.state.notUsed.length
                ? this.state.notUsed.map((item, index) => (
                    item.key === FnMenuId.head_home ?
                        <div key={index} className="bar-item-text" onClick={this.onItemClick.bind(this, item)}>{item.name}{this.state.isEditBar ? null : <div className="bar-item-icon comm-flex-col-center"><Icon type="minus" /></div>}</div>
                        : <div key={index} className="bar-item-text" onClick={this.onItemClick.bind(this, item)}>{item.name} {this.state.isEditBar ? null : <div className="bar-item-icon comm-flex-col-center"><Icon type={used ? "minus" : "plus"} /></div>}</div>
                )) : null;
        }
        return itemBar;
    }


    getAccountMenu(Name, navigationBarView) {
       
        // var accountMenu;
        // if (userInfo.WorkId == "default") {
        //     accountMenu = this.getAllMenu(Name, navigationBarView);
        //     // accountMenu = this.getCashierMenu(Name, navigationBarView);
        // } else {
        //     accountMenu = this.getCashierMenu(Name, navigationBarView)
        // }
        // const accountMenu = this.getAllMenu(Name, navigationBarView);
        return  this.getAllMenu(Name, navigationBarView);
    }
    onChangeListener(tag, data) {
        switch (tag) {
            case 'hideCancelModal':
                isRequest = true
                this.setState({ visibleChain: false })
                break;
            case '进入':
                StorageTools.saveMchInfo(data);
                StorageTools.saveMchCode(data.mchCode)
                this.state.identyListChain.forEach(item => {
                    if (item.MchId == data.MchId) {
                        item._borderColor = '#A451F6'
                        item._color = '#fc1a6e'
                    } else {
                        item._borderColor = '#D8D8D8'
                        item._color = '#333'
                    }
                })
                this.setState({ identyListChain: this.state.identyListChain, AppUid: data.AppUid })
                if (data.Extra.MchType == 0) {
                    isRequest = false
                    //升级
                    this.requestUpgrade(data.mchCode);

                } else {
                    let arrChainObj = {};
                    const ss = data
                    if (ss.IdentityFlag && ss.Extra) {
                        arrChainObj = settingAuthority(ss.IdentityFlag, ss.Extra, true)
                        chainSetting = arrChainObj.chainSetting
                        reportSetting = arrChainObj.reportSetting
                    }
                    if (chainSetting.length) {
                        this.props.history.push(RoutePath.chain_settingContent_setting_chainasetting);
                        // this.props.history.push('/chain/settingContent/setting/chainasetting/0')
                    } else {
                        if (reportSetting.length) {
                            this.props.history.push("/chain/chainReport/overVier");
                        } else {
                            messageInfo('请去连锁后台设置权限')
                        }
                    }

                }
                break;
        }

    }

    //版本到期时间
    getVersionOverTime(userProd){
        let {serial,version,currentTime}  = userProd;
      
        //永久有效
        // debugger
        let time = 0;
        if(currentTime){
            //接口返回数据不全不让使用
            if(!serial || serial === '' || !version && version === ''){
                return -1;
            }
        }else{
            //接口查询失败不限制使用
            return 0;
        }
       
       
        if(serial.expires_time === 0){
            time = DateHelp.getTimeDay(3650);
        }else{
            time = serial.expires_time - currentTime; //是否过期
        }
        let timeOver = 0;
        if (time > 0) { //还有过期时间
            timeOver = DateHelp.getTimeOut(time, version.expiration_day);
            if (timeOver === -1) {
                timeOver = 0;//没到提醒日期
            }else {
                timeOver = timeOver;//提醒日倒计时
            }
        } else {
            timeOver = -1//已过期
        }
       
        
        return timeOver;

        // item.systemTime = data.data.Data.systemTime
        // var time = 0
        // if (item.validTime == 0) {
        //     time = DateHelp.getTimeDay(3650);
        // } else {
        //     time = item.validTime - parseInt(item.systemTime); //版本是否过期
        // }
        // var timeOver = false;
        // if (time > 0) { //还有过期时间
        //     timeOver = DateHelp.getTimeOut(time, item.expirationDay);
        //     if (timeOver == -1) {
        //         item.timeOverDay = 0;//没到提醒日期
        //     }else {
        //         item.timeOverDay = timeOver;//提醒日倒计时
        //     }
        // } else {
        //     item.timeOverDay = -1//已过期
        // }
    }


    render() {
        const {systemHintColse} = this.state;
        const userProd =   StorageTools.getUserProd();

         identity  =  StorageTools.getIdentity();     
         console.log(2132323423,this.props.titleKey)   

        const timeOverDay =  this.getVersionOverTime(userProd)
        

        userInfo = StorageTools.getUserInfo();
        const Name = StorageTools.getStoreName();


        const navigationBarView = <div focusable="true" selectable="false"
            // style={{ height: "84vh", width: "100vw", marginTop: "-3px", border: '1px #ccc solid' }}>
            style={{ height: "calc(100vh - 50px)", width: "100vw", marginTop: "-3px" }}>
            <div style={{ height: 170, width: "100vw", background: '#ededed' }}>
                <div className="comm-row comm-item-justify-between" style={{ paddingLeft: 20, marginRight: 20 }}>
                    <div className="comm-col" >
                        <div className="bar-hint-text">置顶模块</div>
                        <div className="comm-row" >
                            {this.getItembarView(true)}
                        </div>
                        <div className="bar-hint-text">66掌柜模块</div>
                        <div className="comm-row" >
                            {this.getItembarView(false)}
                        </div>
                        <div></div>
                    </div>
                    <IButton style={{ marginTop: 20 }} type="primary" text={this.state.isEditBar ? "编辑" : "保存"} onClick={this.onClickDropdown.bind(this, "edit")}></IButton>
                </div>
            </div>



            <div className="bar-nexe-view" onMouseMove={this.onClickDropdown.bind(this, "hide")} >
                <Button style={{ width: "100%", height: "100%", background: "#00000000" }} onClick={this.onClickDropdown.bind(this, "dropdown")}></Button>
                
            </div>


        </div>
        if (userInfo == null) {
            notify.appSend("user-login-event", { event: "logout" });
            this.props.history.push("/login");
            return "";
        }

        // 获取平台及是否包括外壳
        let os = Env.getOS();
        let frame = Env.isInFrame();
        let appContainerClass = "mod-pg-dash mod-os-" + os;
        if (frame) appContainerClass += " mod-pg-frame-dash-" + os;
        
        const noticeView = this.state.noticeData.map((item, i) => (
            <Panel showArrow={false} header={<div onClick={this.onClickListener.bind(this, "CollapseItem", item)} className="comm-row ">
                <span className="iconfont icon-xinxi" style={{ color: item.open == 0 ? 'red' : "#ccc", fontSize: 14, marginRight: 5 }} />
                <div>
                    <div><span>{item.title}</span></div>
                    <div><span style={{ fontSize: 12 }}>{DateHelp.getTime(item.update_time == 0 ? item.create_time : item.update_time)}</span></div>
                </div>

            </div>} key={item.id}>
                <div style={{ overflowY: "auto" }} className="comm-col">
                    <div className="panel-html" dangerouslySetInnerHTML={{ __html: item.content }} />
                    <div className="comm-text-white">.</div>
                </div>

                {/*<p>{item.title}</p>*/}
            </Panel>
        ))

       return (
            
            <ConfigProvider locale={zhCN}>
                <div className='comm-w-h comm-row'style={{background:"#fff"}}>
                    {/* 左边 */}
                    <div className='comm-h app-left-content' style={{width:"12%"}}>
                        {this.getAccountMenu(Name, navigationBarView)}

                    </div>
                    {/* 右边 */}
                    <div style={{width:"88%",height:"100%"}} className='comm-relative'>
                        <div className='comm-row content-right-head' style={{height:30}}>
                            {
                                <div className="comm-flex-col-center app-massage-view " onClick={this.onClickListener.bind(this, "chain")}>
                                    <Tooltip title="连锁后台" placement="bottom">
                                    <a><span className="iconfont icon-qiyefuwu" style={{ fontSize: 22, marginRight: 20}} /></a>
                                    </Tooltip>
                                </div>
                                }
                                <div className="comm-flex-col-center app-massage-view " onClick={this.onClickListener.bind(this, "massage")}>
                                    <Tooltip title="通知提醒" placement="bottom">
                                    <span className="iconfont icon-tongzhi1" style={{ fontSize: 22, marginRight: 10 }} />
                                    </Tooltip>
                                    {
                                        this.state.noticeCount > 0 ? <div className="message-text"><span style={{  }}>
                                        {this.state.noticeCount}</span> </div> : null
                                    }
                                </div>
                                <div className='comm-row  comm-item-center log-view' style={{marginBottom:20,marginTop:14,marginRight:10}}>
                                    <img style={{width:20,height:20,marginLeft:18,borderRadius:"50%"}} 
                                            src={identity.ShopLogo && identity.ShopLogo != ""?identity.ShopLogo:appHeadImg}/>
                                        <Dropdown visible={this.state.DropdownVisible} onVisibleChange={this.onClickListener.bind(this, "settingPop")} overlay={
                                            <Menu onClick={this.onClickTitle.bind(this)}>
                                                {/*  
                                                <Menu.Item key={FnMenuId.head_setting}>店铺设置</Menu.Item>
                                                <Menu.Divider></Menu.Divider>
                                                <Menu.Item key={FnMenuId.head_rjsq}>软件激活</Menu.Item>
                                                
                                                <Menu.Divider></Menu.Divider> */}
                                                <Menu.Item key={FnMenuId.head_userCenter}>用户中心</Menu.Item>
                                                {
                                                    JSON.parse(StorageTools.getUserIdenty()) ? JSON.parse(StorageTools.getUserIdenty()).length > 1 ? <Menu.Divider></Menu.Divider> : null : null
                                                }
                                                {
                                                    JSON.parse(StorageTools.getUserIdenty()) ? JSON.parse(StorageTools.getUserIdenty()).length > 1 ? <Menu.Item key={FnMenuId.head_qhsf}>切换身份</Menu.Item> : null : null
                                                }
                                                <Menu.Divider></Menu.Divider>
                                                <Menu.Item key={FnMenuId.head_reset}>退出账号</Menu.Item>
                                            </Menu>}>
                                        
                                        <div className="comm-flex-row-center" style={{ marginLeft: 5 }} onClick={this.onClickListener.bind(this, "setting-app")}>
                                            <div className="comm-flex-row-center"  style={{width:Auto, maxWidth: "120px", color:"#000"}}
                                                >
                                                    {Name ? Name : null}
                                                </div>
                                                <Icon type="down" />
                                            </div>
                                        </Dropdown>
                                </div>
                           
                             
                        </div>
                        {AppRoute()}    
                       
                    <div>
                      
                    </div>

                    </div>

                    <div id="mzl-loading" className="loading-class"></div>
                    <IModal colse={true}
                        visible={this.state.noticePopVisible}
                        onCancel={this.onClickListener.bind(this, "noticePopVisible")}
                        onOk={this.onClickListener.bind(this, "noticePopVisible")}
                        footer={false}
                        okText="知道了"
                        footerButton={true}>
                            <div style={{maxHeight:"80vh",overflow:"auto"}} key={this.state.noticePopData.id}>
                                <div className="comm-row comm-item-center comm-item-justify comm-w">
                                    <div style={{ paddingTop: 20 }} >{this.state.noticePopData.title}</div>
                                </div>
                                <div style={{ overflowY: "auto" }} className="comm-col">
                                    <div className="panel-html" dangerouslySetInnerHTML={{ __html: this.state.noticePopData.content }} />
                                </div>
                            </div>
                        </IModal>
                    <IModal
                        colse={true}
                        width='420px'
                        visible={this.state.identyVisible}
                        footer={null}

                        onCancel={() => { this.setState({ identyVisible: false }) }}
                        view={
                            <div>
                                <p style={{ textAlign: 'center', marginTop: 10, color: '#333' }}>请选择要进入的门店和岗位,我要进入</p>
                                <div className='comm-flex-col-between'>
                                    {
                                        this.state.identyList ? this.state.identyList.map((item, index) => {
                                            return (
                                                <div key={index} onClick={this.onClickListener.bind(this, '进入', item)} style={{ fontSize: 16, boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.1)', color: `${item._color}`, margin: '10px 0', border: `1px ${item._borderColor} solid`, borderRadius: 6, width: 340, height: '60px', lineHeight: '60px', textAlign: 'center' }}>
                                                    <span
                                                        style={{ color: `${!item.ShopName ? 'red' : item._color}` }}>{item.ShopName ? item.ShopName : '-'}-{item._IdentityFlag}</span>
                                                </div>
                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                        }>

                    </IModal>
                    <IModal
                        width='300px'
                        visible={this.state.visibleChain}
                        footer={null}
                        onCancel={this.onChangeListener.bind(this, 'hideCancelModal')}
                        view={
                            <div>
                                <p style={{ textAlign: 'center', marginTop: 10, color: '#333' }}>请选择要进入的连锁身份,我要进入</p>
                                <div className='comm-flex-col-between'>
                                    {
                                        this.state.identyListChain ? this.state.identyListChain.map((item, index) => {
                                            return (
                                                <div key={index} onClick={this.onChangeListener.bind(this, '进入', item)}
                                                    style={{
                                                        boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.1)',
                                                        color: `${item._color}`,
                                                        margin: '10px 0',
                                                        border: `1px ${item._borderColor} solid`,
                                                        borderRadius: 6,
                                                        width: '100%',
                                                        height: '50px',
                                                        lineHeight: '50px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer'
                                                    }}>
                                                    <span
                                                        style={{ color: `${!item.ShopName ? 'red' : item._color}` }}>{item.ShopName ? item.ShopName : '-'}-{item._IdentityFlag}</span>
                                                </div>
                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                        }>

                    </IModal>
                    
                    {
                        this.props.tipModalVisible ? <TipModal history={this.props.history} data={this.state.authName} onClick={this.onClickListener.bind(this)} visible={this.state.tipVisible}></TipModal> : null
                    }
                    {
                        this.state.visible ? <IDrawer title={"系统通知"} width={"400px"} onClickListener={this.onClickListener.bind(this)} visible={this.state.visible}>
                            <div><Collapse bordered={false} style={{ background: "#fff" }} onChange={this.onClickListener.bind(this, "collapse")}>

                                {noticeView}
                            </Collapse>
                            </div>
                        </IDrawer> : null
                    }

                </div>

                {/* <Layout style={{background:'#$f9f9fa'}} theme="light">
                    <Header  style={{ position: 'fixed', zIndex: 1, width: '100%' ,background:'#$f9f9fa'}}>
                     
                   
                    </Header>
                    <Sider>{this.getAccountMenu(Name, navigationBarView)}</Sider>
                    <Content>
                        {AppRoute()}    
                    </Content>
                   
                    <div className="container mod-work-space">
                        <div id="mzl-time-over">
                            {
                                systemHintColse && timeOverDay !== 0?
                                <div>
                                    <div className="mzl-time-over-hint">
                                        <span >
                                            您的66掌柜{timeOverDay == -1 ? "已到期" : "还剩" + timeOverDay + "天"}，为了不耽误您的正常使用，请及时续费。客服电话：{mzlPhone} ！
                                        </span>
                                        <div style={{ width: 150, display: 'flex', justifyContent: 'spaceBetween' }}>
                                            <IButton text="立刻激活" onClick={this.onClickTitle.bind(this,{key:FnMenuId.head_rjsq})}/>
                                            <a onClick={this.onClickListener.bind(this, "timeOver")}> <i class='iconfont' style={{ marginLeft: 20, fontSize: 18 }}>&#xe658;</i> </a>
                                        </div>
                                    </div>
                                </div>:null
                            }
                        </div>

                      
                        {AppRoute()}
                    </div>
                    
                </Layout> */}
            </ConfigProvider>
        );
    }


    getUseBarView() {
        // var useBar = this.state.titleBar.length
        //     ? this.state.titleBar.map((item, index) => (
        //         item.key === FnMenuId.head_order || item.key === FnMenuId.head_agree ?
        //             <Menu.Item key={item.key}>
        //                 <IHelpPop code={item.key} > 
        //                 <div 
        //                     className="order-view"> 
        //                     {item.name} {item.key === FnMenuId.head_order ? 
        //                     this.props.updateOrderSize > 0 ? 
        //                         <div className="order-text">
        //                             {this.props.updateOrderSize}
        //                         </div> 
        //                         : null 
        //                         : this.props.agreeTotalSize > 0 ? 
        //                             <div className="order-text">
        //                                 {this.props.agreeTotalSize}
        //                         </div> : null} 
        //                 </div>
        //                 </IHelpPop>
        //                 </Menu.Item>
        //             : <Menu.Item key={item.key}>
        //                 <IHelpPop code={item.key} >
        //                     <div>{item.name}</div>
        //                 </IHelpPop> 
        //             </Menu.Item>

        //     )) : null;
        var useBar = this.state.titleBar.length
            ? this.state.titleBar.map((item, index) => (
                item.child?
                <SubMenu key={item.key} title={item.name}>
                    {item.child.map((ele)=>(
                        <Menu.Item key={ele.key}>
                            {/* <IHelpPop code={ele.key} > */}
                                <div>{ele.name}</div>
                            {/* </IHelpPop>  */}
                        </Menu.Item>
                    ))}
                    
                </SubMenu>:
                 <Menu.Item key={item.key}>
                    {/* <IHelpPop code={item.key} > */}
                        <div>{item.name}</div>
                    {/* </IHelpPop>  */}
                </Menu.Item>

            )) : null;
        return useBar;
    }


    getSelectTitle(){
        const {selectTitleKey,useBar} = this.state;
        // console.log(81231,selectTitleKey,useBar)
        if(selectTitleKey == "" || selectTitleKey ==FnMenuId.head_icon){
            return true;
        }
       
        return useBar.some(item=>{
            return item.key == selectTitleKey
        })
    }

    getSelectTitleName(){
        const {selectTitleKey,notUsed} = this.state;
        if(this.state.selectTitleKey == ""){
            return null;
        }
        let isShow = true;
        notUsed.forEach(item=>{
            if(item.key === selectTitleKey){
                isShow  = false
            }
        });
        return isShow;
    }


    // 获取管理员的菜单
    getAllMenu(userName, aa) {
        return <div className="comm-row comm-item-justify-between">
            <Menu onClick={this.onClickTitle.bind(this)}
                selectedKeys={[this.props.titleKey]}
                // theme="dark"
                // theme="light"
                // defaultSelectedKeys={['1']} 
                mode="inline">
                <div className='comm-row  comm-item-center log-view' style={{marginBottom:20,marginTop:20}}>
                    <img style={{width:Auto,height:20,marginTop:13,marginLeft:24}} src={require("./images/logo-name.svg")} />
                        
                </div>
                {this.getUseBarView()}
                {/* {
                    this.getSelectTitle()?null:
                    <Menu.Item key={this.state.selectTitleKey}>
                        <IHelpPop code={this.state.selectTitleKey} >
                            <div>{this.state.selectTitleName}</div>
                        </IHelpPop> 
                    </Menu.Item>
                }
                <Menu.Item style={{ marginTop: "-10px" }} key={FnMenuId.head_icon}>
                    <Dropdown style={{ marginTop: "-10px" }} overlay={aa} visible={this.state.barVisible} trigger={['click']}>
                        <div className="float-menu-icon" onVisibleChange={this.onClickDropdown.bind(this, "dropdown")} onClick={this.onClickDropdown.bind(this, "dropdown")}>
                            <Icon type="appstore" theme="filled" style={{ fontSize: "18px", color: "#fc1a6e", position: "absolute", top: -17, left: 5 }} />
                        </div>
                    </Dropdown>
                </Menu.Item> */}
            </Menu>

           

            {/* <TrafficLight /> */}
        </div>
    }

  
}

export default App;



function  SShopUrlEntrance(){
    return {
        Id:0,//
        ShopCode:"",//店铺Code
        UserCode:"",//会员Code
        EntranceUserId:0,
        EntrancePwd:"",
        /**创建时间*/
        CreateTime:0,
        /** 更新时间 */
        UpdateTime:0,
        /** 入口ID*/
        EntranceId:0,
        /** 入口*/
        EntranceIdentify:"",
        /** 后台 baseUrl,需要经过计算参数后使用 */
        EntranceBackurl:"",
        /** 进入类型 1 店铺  2 会员 */
        EntranceType:0,
        /** 商户前台的URL */
        EntranceFronturl:"",
    }
    
}